<template>
    <div class="container full-height p-4 fl-y-cc">
        <s-form role="form" @submit="onSubmit" class="card"
                style="width: 500px; box-shadow: 0 4px 24px 0 rgb(0 0 0 / 20%);">
            <h2>Login</h2>
            <small>Login with your Credential</small>
            <validated-input v-model="model.username" type="text" label="Username"/>
            <validated-input v-model="model.password" type="password" label="Password"/>
            <div class="bg-danger p-3" style="text-align: center; border-radius: 5px;" v-if="errorMessage"
                 v-html="errorMessage"></div>
            <div class="mt-3">
                <btn color="primary mr-2" :loading="loading" loading-text="Validating Credential.." text="Login"/>
            </div>
        </s-form>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../data/urls';
import { mapActions } from 'vuex';

export default {
    name  : 'Login',
    props : {
        info_message : {
            type    : String,
            default : ''
        }
    },
    data () {
        return {
            loading      : false,
            errorMessage : '',
            model        : {
                username : '',
                password : ''
            }
        };
    },
    methods : {
        ...mapActions(['setUser']),
        onSubmit () {
            const formValidator = this.$refs.formValidator;
            const that = this;
            this.loading = true;
            axios.form(urls.auth.login, this.model).then(function (response) {
                const json = response.data;
                console.log(json);
                if (json.success === true) {
                    const redirectUrl = sessionStorage.getItem('redirectPath');
                    that.setUser(json.user);
                    that.$router.push(redirectUrl || { path : '/' });
                } else {
                    formValidator.setErrors(json.errors);
                    that.model.username = '';
                    that.model.password = '';
                    that.errorMessage = json.errors.__all__ || '';
                }
                that.loading = false;
            }).catch(function () {
                that.errorMessage = 'Invalid credentials.';
                that.model.username = '';
                that.model.password = '';
                that.loading = false;
            });
        }
    }
};
</script>

<style scoped>

</style>
